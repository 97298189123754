/*@ngInject*/ function S3controller (Upload, Auth, SETTINGS, $mdDialog, $timeout, $http, $scope) {
  const uploadEvent = 's3DownloadProgress';
  /* public vars, set in form */
  this.progress = 0;
  this.uploading = false;
  this.uploadSubmitted = false;
  // this.uploadSuccess = false;
  // this.uploadError = false;

  this.updateProgress = (eventData) => {
    const data = JSON.parse(eventData);
    this.uploadSubmitted = false;
    if(data._id == this.job._id) {
      $scope.$evalAsync(() => {
        if(!this.uploading) {
          this.uploading = true;
        }
        this.progress = Math.min(100, data.data.loaded * 100 / data.data.total);

        if(this.progress == 100) {
          $timeout( () => {
            this.uploading = false;
          }, 500);
        }
      });
    }
    
  };

  this.getProgress = () => progress;

  let socketRef;

  this.setSocketIOlisteners = (socket) => {
    socket.on(uploadEvent, this.updateProgress);
  };

  this.removeSocketIOlisteners = (socket) => {
    socket.removeListener(uploadEvent, this.updateProgress);
  };


  $scope.$on('sq.services.socketIO:unauthorized', (event, socket) => {
    this.removeSocketIOlisteners(socket);
    socketRef = null;
  });

  $scope.$on('sq.services.socketIO:authenticated', (event, socket) => {
    this.setSocketIOlisteners(socket);
    console.info('added socket listener');
    socketRef = socket;
  });

  $scope.$on('sq.services.socketIO:disconnected', (event, socket) => {
    $log.debug('socketio send disconnect in jobs.events.annotation.service');

    //shouldn't be necessary, just a precaution.
    //socket io service should removeAllListners() itself when appropriate;
    this.removeSocketIOlisteners(socket);
  });

  this.$onDestroy = () => {
    if(socketRef) {
      socketRef.removeListener(uploadEvent, this.updateProgress);
    }
  };

  /* private vars */
  let _signature;
  let _policyBase64;
  let _date;
  let _credentials;
  let _expiration;
  let _bucket;
  let _awsSecretKey;
  let _acl = 'private';

  // if(Auth.user.awsSignature && Auth.user.awsSignature.expiration < Date.now()) {
  //   _signature = Auth.user.awsSignature.val;
  // }

  this.hasSignature = () => !!_signature;

  this.generateSignature = (data) => {
    this.uploadSubmitted = true;
    console.info('data from getSignature', data);
    _bucket = data.bucket.$modelValue;
    _awsSecretKey = data.awsSecretKey.$modelValue;
    $http.post(`${SETTINGS.apiEndpoint}jobs/uploadToS3/${this.job._id}`, {
      awsSecretKey: data.awsSecretKey.$modelValue,
      awsAccessId: data.awsAccessId.$modelValue,
      bucket: data.bucket.$modelValue,
      acl : 'private',
      region : data.region.$modelValue,
      //Not in use yet expiration : this.expiration,
    }).then((successResponse) => {
      console.info('data from s3sign', successResponse);
      _signature = successResponse.data.signature;
      _policyBase64 = successResponse.data.policy;
      _date = successResponse.data.date;
      _credentials = successResponse.data.credentials;
      _expiration = successResponse.data.expiration;
    });
  };

  const parentEl = angular.element(document.body);
  this.showDialog = ($event) => {
     $mdDialog.show({
       parent: parentEl,
       templateUrl: 'jobs/results/download/jobs.download.s3.signature-dialog.tpl.html',
       targetEvent: $event,
       locals: {
         generateSignature: this.generateSignature,
       },
       //Doesn't seem to do anything hasBackdrop: true,       // preserveScope: true,
       controller: function S3dialogController($scope, $mdDialog) {
        this.bucket = '';
        this.awsAccessId = '';
        this.awsSecretKey = '';
        this.acl = 'private';
        this.expiration = new Date("2020-01-01T00:00:00Z");
        this.region = '';//'us-west-2';
        // $scope.items = items;
        this.closeDialog = () => {
          $mdDialog.hide();
        };

        this.signAndUpload = (formData) => {
          this.generateSignature(formData);
          this.closeDialog();
        };
      },
       controllerAs: '$ctrl',
       bindToController: true,
    });
  };
}

angular.module('sq.jobs.download.s3.component', ['ngFileUpload', 'sq.user.auth'])
.component('sqDownloadJobS3', {
  bindings: {
    // The submission object (could be search or main job submission)
    job: '<',
    onStarted: '&',
    onCompleted: '&',
    onFailed: '&',
  }, // isolate scope
  templateUrl: 'jobs/results/download/jobs.download.s3.tpl.html',
  controller: S3controller,
  controllerAs: '$ctrl',
  // transclude: {
  //   headerActions : '?headerActions',
  //   body : '?cardContent',
  // },
});
